export const Discord = (props) => {
    return(
        <svg 
            id="Bold"
            enable-background="new 0 0 24 24"
            height="40"
            viewBox="0 0 24 24"
            width="40"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="m3.58 21.196h14.259l-.681-2.205 1.629 1.398 1.493 1.338 2.72 2.273v-21.525c-.068-1.338-1.22-2.475-2.648-2.475l-16.767.003c-1.427 0-2.585 1.139-2.585 2.477v16.24c0 1.411 1.156 2.476 2.58 2.476zm10.548-15.513-.033.012.012-.012zm-7.631 1.269c1.833-1.334 3.532-1.27 3.532-1.27l.137.135c-2.243.535-3.26 1.537-3.26 1.537s.272-.133.747-.336c3.021-1.188 6.32-1.102 9.374.402 0 0-1.019-.937-3.124-1.537l.186-.183c.291.001 1.831.055 3.479 1.26 0 0 1.844 3.15 1.844 7.02-.061-.074-1.144 1.666-3.931 1.726 0 0-.472-.534-.808-1 1.63-.468 2.24-1.404 2.24-1.404-.535.337-1.023.537-1.419.737-.609.268-1.219.4-1.828.535-2.884.468-4.503-.315-6.033-.936l-.523-.266s.609.936 2.174 1.404c-.411.469-.818 1.002-.818 1.002-2.786-.066-3.802-1.806-3.802-1.806 0-3.876 1.833-7.02 1.833-7.02z"/><path d="m14.308 12.771c.711 0 1.29-.6 1.29-1.34 0-.735-.576-1.335-1.29-1.335v.003c-.708 0-1.288.598-1.29 1.338 0 .734.579 1.334 1.29 1.334z"/><path d="m9.69 12.771c.711 0 1.29-.6 1.29-1.34 0-.735-.575-1.335-1.286-1.335l-.004.003c-.711 0-1.29.598-1.29 1.338 0 .734.579 1.334 1.29 1.334z"/>
        </svg>
    );
}

export const Github = (props) => {
    return(
        <svg 
            id="Bold"
            enable-background="new 0 0 24 24"
            height="40"
            viewBox="0 0 24 24"
            width="40"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="m12 .5c-6.63 0-12 5.28-12 11.792 0 5.211 3.438 9.63 8.205 11.188.6.111.82-.254.82-.567 0-.28-.01-1.022-.015-2.005-3.338.711-4.042-1.582-4.042-1.582-.546-1.361-1.335-1.725-1.335-1.725-1.087-.731.084-.716.084-.716 1.205.082 1.838 1.215 1.838 1.215 1.07 1.803 2.809 1.282 3.495.981.108-.763.417-1.282.76-1.577-2.665-.295-5.466-1.309-5.466-5.827 0-1.287.465-2.339 1.235-3.164-.135-.298-.54-1.497.105-3.121 0 0 1.005-.316 3.3 1.209.96-.262 1.98-.392 3-.398 1.02.006 2.04.136 3 .398 2.28-1.525 3.285-1.209 3.285-1.209.645 1.624.24 2.823.12 3.121.765.825 1.23 1.877 1.23 3.164 0 4.53-2.805 5.527-5.475 5.817.42.354.81 1.077.81 2.182 0 1.578-.015 2.846-.015 3.229 0 .309.21.678.825.56 4.801-1.548 8.236-5.97 8.236-11.173 0-6.512-5.373-11.792-12-11.792z"/>
        </svg>
    );
}

export const Twitter = (props) => {
    return(
        <svg
            height="40pt"
            viewBox="0 0 520 520" 
            width="40pt"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="m256 0c-141.363281 0-256 114.636719-256 256s114.636719 256 256 256 256-114.636719 256-256-114.636719-256-256-256zm116.886719 199.601562c.113281 2.519532.167969 5.050782.167969 7.59375 0 77.644532-59.101563 167.179688-167.183594 167.183594h.003906-.003906c-33.183594 0-64.0625-9.726562-90.066406-26.394531 4.597656.542969 9.277343.8125 14.015624.8125 27.53125 0 52.867188-9.390625 72.980469-25.152344-25.722656-.476562-47.410156-17.464843-54.894531-40.8125 3.582031.6875 7.265625 1.0625 11.042969 1.0625 5.363281 0 10.558593-.722656 15.496093-2.070312-26.886718-5.382813-47.140624-29.144531-47.140624-57.597657 0-.265624 0-.503906.007812-.75 7.917969 4.402344 16.972656 7.050782 26.613281 7.347657-15.777343-10.527344-26.148437-28.523438-26.148437-48.910157 0-10.765624 2.910156-20.851562 7.957031-29.535156 28.976563 35.554688 72.28125 58.9375 121.117187 61.394532-1.007812-4.304688-1.527343-8.789063-1.527343-13.398438 0-32.4375 26.316406-58.753906 58.765625-58.753906 16.902344 0 32.167968 7.144531 42.890625 18.566406 13.386719-2.640625 25.957031-7.53125 37.3125-14.261719-4.394531 13.714844-13.707031 25.222657-25.839844 32.5 11.886719-1.421875 23.214844-4.574219 33.742187-9.253906-7.863281 11.785156-17.835937 22.136719-29.308593 30.429687zm0 0"/>
        </svg>
    );
}

export const Book = (props) => {
    return(
        <svg
            viewBox="0 0 16 16"
            width="30"
            height="30"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path fillRule="evenodd" d="M2 2.5A2.5 2.5 0 014.5 0h8.75a.75.75 0 01.75.75v12.5a.75.75 0 01-.75.75h-2.5a.75.75 0 110-1.5h1.75v-2h-8a1 1 0 00-.714 1.7.75.75 0 01-1.072 1.05A2.495 2.495 0 012 11.5v-9zm10.5-1V9h-8c-.356 0-.694.074-1 .208V2.5a1 1 0 011-1h8zM5 12.25v3.25a.25.25 0 00.4.2l1.45-1.087a.25.25 0 01.3 0L8.6 15.7a.25.25 0 00.4-.2v-3.25a.25.25 0 00-.25-.25h-3.5a.25.25 0 00-.25.25z"/>
        </svg>
    )
}

export const Helmet = (props) => {
    return(
        <svg
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="40px"
            height="40px"
	        viewBox="0 0 512 512"
            space="preserve"
            {...props}
        >
            <g>
            	<g>
            		<path d="M482,363.58V316c0-49.388-15.925-96.331-46.053-135.753c-26.743-34.993-63.719-61.83-105.003-76.443
            			C329.795,80.007,310.077,61,286,61h-60c-24.077,0-43.795,19.007-44.944,42.804c-41.284,14.613-78.26,41.45-105.003,76.443
            			C45.925,219.669,30,266.612,30,316v47.58C12.541,369.772,0,386.445,0,406c0,24.813,20.187,45,45,45h422c24.813,0,45-20.187,45-45
            			C512,386.445,499.459,369.772,482,363.58z M60,316c0-54.091,23.106-104.728,61-140.855V226c0,8.284,6.716,15,15,15s15-6.716,15-15
            			v-74.383c9.452-6.034,19.477-11.298,30-15.679V256c0,8.284,6.716,15,15,15s15-6.716,15-15V114.932c0.001-0.041,0-0.082,0-0.123
            			V106c0-8.271,6.729-15,15-15h60c8.271,0,15,6.729,15,15v8.805c0,0.043-0.001,0.085,0,0.128V256c0,8.284,6.716,15,15,15
            			s15-6.716,15-15V135.938c10.523,4.382,20.548,9.646,30,15.679V226c0,8.284,6.716,15,15,15s15-6.716,15-15v-50.855
            			c37.894,36.127,61,86.764,61,140.855v45H60V316z M467,421H45c-8.271,0-15-6.729-15-15s6.729-15,15-15h422c8.271,0,15,6.729,15,15
            			S475.271,421,467,421z"/>
            	</g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
        </svg>
    );
}

export const ReactIco = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="-11.5 -10.23174 23 20.46348"
            width="40px"
            height="40px"
            {...props}
        >
            <circle
                cx="0"
                cy="0"
                r="2.05"
                fill="#61dafb"
            ></circle>
            <g stroke="#61dafb" stroke-width="1" fill="none">
                <ellipse rx="11" ry="4.2"></ellipse>
                <ellipse rx="11" ry="4.2" transform="rotate(60)"></ellipse>
                <ellipse rx="11" ry="4.2" transform="rotate(120)"></ellipse>
            </g>
        </svg>
    )
}

export const VisualStuio = (props) => {
    return (
    <svg 
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
    	viewBox="0 0 512 512"
        enable-background="new 0 0 512 512"
        {...props}
    >
        <circle fill="#6E64C3" cx="256" cy="256" r="256"/>
        <path fill="#5046AF" d="M405.21,129.405l-70.646-28.547l-124.44,120.581l-75.183-57.035l-28.518,14.259l0.366,150.674
        	l180.73,180.73C414.032,494.532,512,386.712,512,256c0-6.947-0.287-13.827-0.83-20.635L405.21,129.405z"/>
        <path fill="#FFFFFF" d="M405.21,129.405l-70.646-28.547l-124.44,120.581l-75.183-57.035l-28.518,14.259l0.366,150.674
        	l28.518,14.259l75.183-57.035l124.44,120.581l70.646-28.547L405.21,129.405z M142.437,285.913v-63.825L177.718,254h0.366
        	L142.437,285.913z M327.477,307.139L256.549,254h-0.366l71.294-53.139L327.477,307.139L327.477,307.139z"/>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
    </svg>
    );
}

export const JavaScript = (props) => {
    return(
    <svg 
        version="1.1" 
        id="Capa_1" 
        xmlns="http://www.w3.org/2000/svg"
        x="0px" 
        y="0px"
        viewBox="0 0 512 512"
        enable-background="new 0 0 512 512"
        {...props}
    >
        <g transform="translate(1 1)">
        	<polygon fill="#FFE100" points="7.501,502.498 502.498,502.498 502.498,7.501 7.501,7.501 	"/>
        	<polygon fill="#FFA800" points="476.895,502.498 502.498,502.498 502.498,7.501 476.895,7.501 	"/>
        	<polygon fill="#FFFFFF" points="7.501,502.498 33.104,502.498 33.104,7.501 7.501,7.501 	"/>
        	<path d="M502.499,511H7.501C2.806,511-1,507.194-1,502.499V7.501C-1,2.806,2.806-1,7.501-1h494.998C507.194-1,511,2.806,511,7.501
        		v494.998C511,507.194,507.194,511,502.499,511z M16.002,493.998h477.996V16.002H16.002V493.998z"/>
        	<path d="M391.551,468.328c-42.334,0-76.777-34.442-76.777-76.777c0-4.695,3.807-8.501,8.501-8.501c4.695,0,8.501,3.806,8.501,8.501
        		c0,32.959,26.815,59.775,59.775,59.775s59.775-26.815,59.775-59.775s-26.815-59.775-59.775-59.775
        		c-42.334,0-76.777-34.442-76.777-76.777s34.442-76.776,76.777-76.776s76.777,34.442,76.777,76.777c0,4.695-3.806,8.501-8.501,8.501
        		c-4.695,0-8.501-3.806-8.501-8.501c0-32.96-26.815-59.775-59.775-59.775S331.776,222.04,331.776,255
        		c0,32.959,26.815,59.775,59.775,59.775c42.334,0,76.777,34.442,76.777,76.777S433.885,468.328,391.551,468.328z"/>
        	<path d="M212.328,468.328c-42.334,0-76.777-34.442-76.777-76.777c0-4.695,3.806-8.501,8.501-8.501s8.501,3.806,8.501,8.501
        		c0,32.959,26.814,59.775,59.775,59.775s59.775-26.815,59.775-59.775V186.724c0-4.695,3.806-8.501,8.501-8.501
        		s8.501,3.806,8.501,8.501v204.826C289.103,433.885,254.662,468.328,212.328,468.328z"/>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
    </svg>
    );
}

export const Cpp = (props) => {
    return (   
        <svg
            version="1.1" 
            id="Capa_1" 
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
        	viewBox="0 0 512 512"
            enable-background="new 0 0 512 512"
            {...props}
        >
            <path fill="#2D3A3E" d="M512,256c0,45.265-11.745,87.782-32.36,124.677c0,0.01,0,0.01,0,0.01
            	c-3.897,6.969-8.108,13.74-12.612,20.281c-6.729,9.78-14.127,19.069-22.12,27.794c-34.994,38.254-81.45,65.839-133.935,77.312
            	C293.261,509.962,274.871,512,256,512s-37.271-2.038-54.983-5.925c-52.475-11.483-98.931-39.058-133.914-77.302
            	c-7.753-8.464-14.942-17.45-21.494-26.896c-4.754-6.823-9.174-13.897-13.249-21.191c0-0.01,0-0.01,0-0.01
            	C11.745,343.782,0,301.265,0,256C0,114.615,114.615,0,256,0S512,114.615,512,256z"/>
            <path fill="#004284" d="M479.765,181.906v195.082c0,1.233-0.042,2.466-0.125,3.699
            	c-9.707,17.377-21.389,33.499-34.732,48.076l-133.935,77.312C293.261,509.962,274.871,512,256,512s-37.271-2.038-54.983-5.925
            	l-110.07-63.53l-23.845-13.772c-13.354-14.576-25.036-30.71-34.743-48.086c-0.084-1.233-0.125-2.466-0.125-3.699V181.906
            	c0-19.581,10.459-37.669,27.397-47.47l168.97-97.541c16.959-9.801,37.836-9.801,54.794,0l135.158,78.033l33.813,19.508
            	C469.305,144.238,479.765,162.325,479.765,181.906z"/>
            <path fill="#6399D4" d="M472.837,155.261L45.61,401.878c-4.754-6.823-9.174-13.897-13.249-21.191c0-0.01,0-0.01,0-0.01
            	c-0.084-1.223-0.125-2.456-0.125-3.688V181.906c0-19.581,10.449-37.669,27.397-47.47l168.97-97.541
            	c16.948-9.801,37.825-9.801,54.784,0l135.168,78.033l33.813,19.508C461.093,139.483,468.093,146.745,472.837,155.261z"/>
            <path fill="#00579E" d="M479.765,181.906v195.082c0,1.233-0.042,2.466-0.125,3.688c0,0.01,0,0.01,0,0.01
            	c-3.897,6.969-8.108,13.74-12.612,20.281l-209.92-121.177l215.73-124.531C477.309,163.286,479.765,172.419,479.765,181.906z"/>
            <g>
            	<path fill="#F2F2F2" d="M321.663,317.048c-12.804,23.061-37.398,38.664-65.649,38.664
            		c-41.447,0-75.046-33.599-75.046-75.046s33.599-75.046,75.046-75.046c27.704,0,51.891,15.009,64.899,37.335l66.353-38.305
            		c-26.234-45.23-75.201-75.64-131.252-75.64c-83.754,0-151.654,67.885-151.654,151.654c0,83.754,67.901,151.654,151.654,151.654
            		c56.597,0,105.955-31.003,132.002-76.968L321.663,317.048z"/>
            	<polygon fill="#F2F2F2" points="404.902,269.756 387.661,269.756 387.661,252.515 370.943,252.515 370.943,269.756 
            		353.702,269.756 353.702,286.474 370.943,286.474 370.943,303.715 387.661,303.715 387.661,286.474 404.902,286.474 	"/>
            	<polygon fill="#F2F2F2" points="467.596,269.756 450.355,269.756 450.355,252.515 433.637,252.515 433.637,269.756 
            		416.396,269.756 416.396,286.474 433.637,286.474 433.637,303.715 450.355,303.715 450.355,286.474 467.596,286.474 	"/>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
        </svg>
    );
}